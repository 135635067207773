// dependencies
import React, { useEffect, useCallback } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { Box, Paper, Grid, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import range from 'lodash/range'
import { getRegion } from '@helpers/geo-location'
import { getTypeKey } from '@helpers/strapi'
import usePageAnalytics from '@hooks/usePageAnalytics'
import Breadcrumbs from '@templates/strapi-cms/content-types/Breadcrumbs'
import FavoriteCTA from '@components/favorites/FavoriteCTA'
import EmptyFavorites from '@components/favorites/EmptyFavorites'
import ProductLoader from '@components/favorites/ProductLoader'
import ProductTile from '@components/ProductTile'
import Layout from '@components/layout'
import useStore from '@store'
import { useNotifications } from '@context/notifications'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'
import PageBanner from '@templates/strapi-cms/content-types/Banner'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import PageSlider from '@templates/strapi-cms/content-types/SliderWrapper'
import PageGrid from '@templates/strapi-cms/content-types/Grid'
import PageContentSection from '@templates/strapi-cms/content-types/ContentSection'
import PageContentGroup from '@templates/strapi-cms/content-types/ContentGroup'

const RTG_FAVORITES_SNACKBAR = '__RTG_FAVORITES_SNACKBAR__'

const breadcrumbData = [{ label: 'Favorites', level: 1, category: 'favorites' }]
const region = getRegion()

const availableTypes = {
  PageBanner,
  PageContentGroup,
  PageContentSection,
  PageSlider,
  PageGrid,
}

// * INTERNALS

const useSnackbar = () => {
  const { actions } = useModalsContext()
  const { enqueue, removeById } = useNotifications()

  const show = useCallback(() => {
    const id = enqueue({
      variant: 'snackbar',
      body: 'Log in or create an account to save & view favorite items across devices',
      persist: true,
      actions: [
        {
          text: 'log in',
          onClick: () => {
            removeById(id)
            actions.login()
          },
        },
        {
          text: 'create account',
          onClick: () => {
            removeById(id)
            actions.createAccount()
          },
        },
      ],
    })
  }, [actions, enqueue, removeById])
  return show
}

const useFavBannerQuery = () => {
  const data = useStaticQuery(graphql`
    query FavoritesPageBanners {
      strapiFavoritesPageContent {
        TopBanner {
          Banner {
            ...StrapiPageBannerFragment
          }
        }
        Top_ProductTiles_DefaultContent {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
        Top_ProductTiles_Content_SE {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
        Top_ProductTiles_Content_FL {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
        }
        Top_ProductTiles_Content_TX {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
        }
        Top_ProductTiles_Content_OOM {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
        }
        Bottom_ProductTiles_DefaultContent {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
        Bottom_ProductTiles_Content_SE {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
        Bottom_ProductTiles_Content_FL {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
        Bottom_ProductTiles_Content_TX {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
        Bottom_ProductTiles_Content_OOM {
          __typename
          ... on PageBanner {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          ... on PageSlider {
            Slider {
              ...StrapiSliderfragment
            }
          }
          ... on PageGrid {
            __typename
            Grid {
              ...StrapiPageGridFragment
            }
          }
          ... on PageContentGroup {
            ContentGroup {
              ...StrapiContentGroupFragment
            }
          }
          ... on PageContentSection {
            ContentSection {
              ...StrapiContentSectionFragment
            }
          }
        }
      }
      allStrapiFinanceBannerGroup(filter: { Title: { eq: "Production Finance Banner Group" } }) {
        edges {
          node {
            id
            Title
            SE {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            FL {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            TX {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            PR {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
          }
        }
      }
    }
  `)
  return data || null
}

// * STYLED

const PageHeading = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ProductTileContainer = styled(Grid)({
  width: '100%',
})

const NoPaddingGrid = styled(Grid)`
  div[class*='MobileBannerWithImageWrapper'] > div:first-of-type {
    padding: 0 10px;
  }
`

const FavoritesContentGeneratorStyles = styled(Grid)({
  width: '100%',
  display: 'block',
  maxWidth: '104rem',
  '& .MuiGrid-spacing-xs-2:not(.grid-wrapper), & .MuiGrid-spacing-xs-4:not(.grid-wrapper), & .MuiGrid-spacing-xs-6:not(.grid-wrapper)': {
    margin: 0,
  },
})

// * COMPONENT

const Favorites = () => {
  const { user } = useFirebaseContext()
  const enqueue = useSnackbar()

  const favorites = useStore(store => store.favorites.list)
  const sortedFavorites = useStore(store => store.favorites.sortedList)
  const isLoading = useStore(store => store.favorites.isLoading)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { strapiFavoritesPageContent, allStrapiFinanceBannerGroup } = useFavBannerQuery()
  const topBanner = strapiFavoritesPageContent?.TopBanner
  const financeBanner = allStrapiFinanceBannerGroup?.edges?.[0]?.node[region] || {}
  const topProductTilesContent =
    strapiFavoritesPageContent?.[`Top_ProductTiles_Content_${region}`] ||
    strapiFavoritesPageContent?.Top_ProductTiles_DefaultContent
  const bottomProductTilesContent =
    strapiFavoritesPageContent?.[`Bottom_ProductTiles_Content_${region}`] ||
    strapiFavoritesPageContent?.Bottom_ProductTiles_DefaultContent

  usePageAnalytics({ type: 'favorites', title: 'Favorites', path: '/favorites' }, null, null)

  useEffect(() => {
    setTimeout(() => {
      if (user || favorites.isEmpty() || sessionStorage.getItem(RTG_FAVORITES_SNACKBAR)) {
        return
      }
      enqueue()
      sessionStorage.setItem(RTG_FAVORITES_SNACKBAR, 'already-displayed')
    }, 1200)
  }, [enqueue, favorites, user])

  return (
    <>
      <Helmet title="Favorites" />
      <PageHeading>
        <Breadcrumbs productBreadcrumbs={breadcrumbData} />
        {!favorites.isEmpty() && (
          <Typography>
            {favorites.size} Favorite {favorites.size > 1 ? 'items' : 'item'}
          </Typography>
        )}
      </PageHeading>
      <NoPaddingGrid container sx={{ display: 'block', width: '100%' }}>
        <PageBanner data={topBanner} contentIndex={1} />
      </NoPaddingGrid>
      <FavoritesContentGenerator ContentTypes={topProductTilesContent} />
      <Paper sx={{ p: { md: 2 }, mt: 1 }}>
        <FavoriteCTA />
        {isLoading ? (
          <Grid container spacing={isMobile ? 0.625 : 1.25}>
            {range(isMobile ? 1 : 3).map(key => (
              <ProductTileContainer sm={4} sx={12} item key={key}>
                <ProductLoader />
              </ProductTileContainer>
            ))}
          </Grid>
        ) : (
          <>
            {favorites.isEmpty() ? (
              <>
                <EmptyFavorites />
              </>
            ) : (
              // By design team, tile spacing should be 5px on mobile and 10px desktop
              <Grid container spacing={isMobile ? 0.625 : 1.25}>
                {sortedFavorites.map((sku, index) => (
                  <ProductTileContainer sm={4} sx={12} item key={sku}>
                    <ProductTile sku={sku} index={index} source="favorites" />
                  </ProductTileContainer>
                ))}
              </Grid>
            )}
          </>
        )}
      </Paper>
      <FavoritesContentGenerator ContentTypes={bottomProductTilesContent} />
      <Grid container sx={{ display: 'block', width: '100%' }}>
        <PageBanner data={financeBanner} />
      </Grid>
    </>
  )
}

const FavoritesPage = () => (
  <Layout>
    <Helmet title="Favorites - Rooms To Go" meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
    <Favorites />
  </Layout>
)

const FavoritesContentGenerator = ({ ContentTypes }) => {
  if (ContentTypes && Array.isArray(ContentTypes)) {
    return (
      <FavoritesContentGeneratorStyles container id="favorites-content-generator" style={{ marginTop: '1rem' }}>
        {ContentTypes?.map((contentData, index) => {
          if (!contentData?.__typename) {
            return null
          }

          const Component = availableTypes[contentData.__typename]

          if (contentData.__typename === 'PageContentGroup' || contentData.__typename === 'PageContentSection') {
            const componentData =
              contentData.__typename === 'PageContentGroup' ? contentData?.ContentGroup : contentData?.ContentSection
            return Component ? <Component data={componentData} index={index} key={getTypeKey(contentData)} /> : null
          }

          return Component ? <Component contentIndex={index} data={contentData} key={getTypeKey(contentData)} /> : null
        })}
      </FavoritesContentGeneratorStyles>
    )
  }
  return null
}

FavoritesContentGenerator.propTypes = {
  ContentTypes: PropTypes.array,
}

export default FavoritesPage
